import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const iDMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Invoice Menu",
        icon: ICONS.booking,
        children: [
          {
            title: "Billing Details",
            path: PATH_DASHBOARD.general.cumulativeBillingListing,
            icon: ICONS.analytics,
          },
          {
            title: "Invoice List",
            path: PATH_DASHBOARD.general.invoiceBillingListing,
            icon: ICONS.analytics,
          },
        ],
      },
      {
        title: "Client SKU",
            children: [
              {
                title: "Client SKU Map  listing",
                path: PATH_DASHBOARD.general.clientSKUListing,
              },
            ],
      },  
      

    ],
  },
];

export { iDMenu  };
